<template>
  <b-table
    ref="selectableTable"
    :items="items"
    :fields="fields"
    responsive
  >
    <template #cell(name)="data">
      <b-form-input
        v-if="data.item.id > 1 || isPlaintext"
        v-model="data.value"
        plaintext
      />
      <b-form-input
        v-else
        v-model="data.value"
        @blur="handleNamePerBlur(data)"
      />
    </template>
    <template #cell(price)="data">
      <b-form-input
        v-if="isPlaintext"
        v-model="data.value"
        plaintext
      />
      <b-form-input
        v-else
        v-model="data.value"
        @blur="handlePriceBlur(data)"
      />
    </template>
    <template #cell(qty)="data">
      <b-form-spinbutton
        id="sb-inline"
        v-model="data.value"
        min="0"
        inline
        @change="handleSpinChange(data)"
      />
    </template>
    <template #cell(charged_per)="data">
      <b-form-input
        v-if="data.item.id > 1 || isPlaintext"
        v-model="data.value"
        plaintext
      />
      <b-form-input
        v-else
        v-model="data.value"
        @blur="handleNamePerBlur(data)"
      />
    </template>
    <template #cell(totalPrice)="data">
      AU${{ data.value }}
    </template>
  </b-table>
</template>

<script>
import {
  BTable, BFormSpinbutton, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BTable, BFormSpinbutton, BFormInput,
  },
  props: {
    extraServices: {
      type: Array,
      require: true,
      default: () => [],
    },
    isPlaintext: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'name', label: '服务名称' },
        { key: 'price', label: '价格(AU$)' },
        { key: 'qty', label: '数量' },
        { key: 'charged_per', label: '收费单位' },
        { key: 'totalPrice', label: '总计' },
      ],
      selected: [],
    }
  },
  watch: {
    extraServices: {
      handler(val) {
        this.items = [].concat(val)
        this.items.forEach(item => {
          // eslint-disable-next-line
          item.totalPrice = (Number(item.price) * item.qty).toFixed(2)
        })
      },
      immediate: true,
    },
  },
  methods: {
    handleAddDiy() {
      this.items.push({
        id: 0,
        name: '',
        price: '',
        qty: 1,
        charged_per: 'item',
        totalPrice: '0.00',
      })
    },
    handleCalculatePrice(price, qty) {
      return (Number(price) * qty).toFixed(2)
    },
    handleSpinChange(data) {
      this.items[data.index].totalPrice = this.handleCalculatePrice(data.item.price, data.value)
      this.items[data.index].qty = data.value
      // 不能放handleCalculatePrice，因为获取的时候会延迟，导致计算错误
      this.$emit('calculatePrice')
    },
    handlePriceBlur(data) {
      this.items[data.index].price = data.value
      this.items[data.index].totalPrice = this.handleCalculatePrice(data.value, data.item.qty)
      this.$emit('calculatePrice')
    },
    handleNamePerBlur(data) {
      this.items[data.index][data.field.key] = data.value
    },
  },
}
</script>

<style lang="scss" scoped>
.card-b{
  border-right: 1px solid #000;
  height: 50.38px;
  line-height: 50.38px;
}
.card-item {
  width: 100%;
  text-align: center;
}
</style>
