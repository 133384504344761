<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <b-button
            variant="relief-info"
            class="mr-1"
          >
            保存
          </b-button>
          <b-button
            variant="relief-success"
            class="mr-1"
          >
            打印运单
          </b-button>
          <b-button
            variant="relief-primary"
          >
            添加包裹
          </b-button>
        </div>
      </div>
    </b-card>
    <b-card>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="运单ID"
            label-for="shipment_id"
          >
            <b-form-input
              id="shipment_id"
              v-model="detail.shipment_id"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="客户"
            label-for="client"
          >
            <b-form-input
              id="client"
              v-model="clientName"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="物流"
            label-for="courier"
          >
            <b-form-input
              id="courier"
              v-model="detail.courier.name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="运单目的地"
            label-for="courier"
          >
            <b-form-input
              id="courier"
              v-model="detail.recipient.country"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="仓库位置"
            label-for="courier"
          >
            <b-form-input
              id="courier"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="创建日期"
            label-for="mark"
          >
            <b-form-input
              id="mark"
              v-model="detail.ShipmentLogs[0].createAt"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="运单状态"
            label-for="mark"
          >
            <b-form-input
              id="mark"
              v-model="detail.shipment_status_note"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="批次名称"
            label-for="mark"
          >
            <b-form-input
              id="mark"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h3 class="card-h3">
        运费概览
      </h3>
      <b-table
        ref="selectableTable"
        class="mt-2"
        :items="freightItems"
        :fields="freightFields"
        responsive
      >
        <template #cell(estimated_fee)="data">
          AU$<span>{{ data.value }}</span>
        </template>
        <template #cell(insurance_fee)="data">
          AU$<span>{{ data.value }}</span>
        </template>
        <template #cell(tax_fee)="data">
          AU$<span>{{ data.value }}</span>
        </template>
        <template #cell(extra_fee)="data">
          AU$<span>{{ data.value }}</span>
        </template>
        <template #cell(delivery_fee)="data">
          AU$<span>{{ data.value }}</span>
        </template>
        <template #cell(shipment_extraService)="data">
          AU$<span>{{ data.value }}</span>
        </template>
        <template #cell(parcel_extraService)="data">
          AU$<span>{{ data.value }}</span>
        </template>
        <template #cell(sub_total)="data">
          AU$<span>{{ data.value }}</span>
        </template>
        <template #cell(adjust_fee)>
          <b-input-group
            prepend="AU$"
            class="input-group-merge adjust-input"
          >
            <b-form-input
              v-model="adjust_fee"
              placeholder="0.00"
              @blur="handleAdjustBlur"
            />
          </b-input-group>
        </template>
        <template #cell(total_cost)="data">
          AU$<span>{{ data.value }}</span>
        </template>
      </b-table>
    </b-card>
    <b-card>
      <div class="card-h3 d-flex justify-content-between align-items-center">
        <h3>
          运单概览
        </h3>
      </div>
      <b-row class="mt-2">
        <b-col cols="2">
          预计发包数量:{{ detail.packagesNum }}
        </b-col>
        <b-col cols="2">
          包裹数量:{{ detail.packagesNum }}
        </b-col>
        <b-col cols="2">
          总计费重量:{{ detail.packagesChargeableWeight }}KG
        </b-col>
        <b-col cols="2">
          体积:
        </b-col>
        <b-col cols="2">
          总运输数量:AU${{ Number(detail.estimatedCost).toFixed(2) }}
        </b-col>
        <b-col cols="2">
          末端派送:{{ detail.courier.name }}
        </b-col>
      </b-row>
      <b-table
        ref="selectableTable"
        :items="detail.packages"
        :fields="shipmentFields"
        responsive
        class="mt-2"
      >
        <template #cell(index)="data">
          <span>{{ data.index + 1 }}</span>
        </template>
        <template #cell(track_no)="data">
          <b-form-input
            id="mark"
            v-model="data.value"
            placeholder="追踪号码"
          />
        </template>
        <template #cell(dead_weight)="data">
          <b-form-input
            id="mark"
            v-model="data.value"
            placeholder="实际重量"
          />
        </template>
        <template #cell(height)="data">
          <b-form-input
            id="mark"
            v-model="data.value"
            placeholder="高"
          />
        </template>
        <template #cell(width)="data">
          <b-form-input
            id="mark"
            v-model="data.value"
            placeholder="宽"
          />
        </template>
        <template #cell(length)="data">
          <b-form-input
            id="mark"
            v-model="data.value"
            placeholder="长"
          />
        </template>
        <template #cell(v_weight)="data">
          <b-form-input
            id="mark"
            v-model="data.value"
            placeholder="体积重量"
            disabled
          />
        </template>
        <template #cell(c_weight)="data">
          <b-form-input
            id="mark"
            v-model="data.value"
            placeholder="用于计算的重量"
            disabled
          />
        </template>
        <template #cell(operate)="data">
          <feather-icon
            icon="Trash2Icon"
            size="24"
            style="color:red"
            @click="handleDeletePackage(data)"
          />
        </template>
      </b-table>
    </b-card>
    <b-card>
      <h3 class="card-h3">
        运单申报
      </h3>
      <declare
        ref="declare"
        class="mt-2"
        :declare="detail.shipmentDeclarationForm"
      />
    </b-card>
    <b-card>
      <div class="card-h3 mb-1 d-flex justify-content-between align-items-center">
        <h3 style="margin:0;">
          额外服务
        </h3>
        <b-button
          variant="relief-primary"
          @click="handleAddDiy"
        >
          添加自定义服务
        </b-button>
      </div>
      <extra-service
        ref="extraservice"
        :extraServices="detail.extraServices"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormInput, BFormGroup, BTable, BInputGroup,
} from 'bootstrap-vue'
import Declare from '../agent/components/declare.vue'
import ExtraService from '../agent/components/extraService.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BInputGroup,
    Declare,
    ExtraService,
  },
  data() {
    return {
      freightItems: [],
      freightFields: [
        { key: 'estimated_fee', label: '运输费用' },
        { key: 'insurance_fee', label: '保险' },
        { key: 'tax_fee', label: '税费' },
        { key: 'extra_fee', label: '物流附加费用' },
        { key: 'delivery_fee', label: '派送费用' },
        { key: 'shipment_extraService', label: '运单额外服务' },
        { key: 'parcel_extraService', label: '包裹额外服务' },
        { key: 'sub_total', label: 'Sub Total' },
        { key: 'adjust_fee', label: '价格调整(+/-)' },
        { key: 'total_cost', label: '总价' },
      ],
      shipmentFields: [
        { key: 'index', label: 'No.' },
        { key: 'track_no', label: '追踪号码' },
        { key: 'dead_weight', label: '实际重量(KG)' },
        { key: 'height', label: '高(CM)' },
        { key: 'width', label: '宽(CM)' },
        { key: 'length', label: '长(CM)' },
        { key: 'volumetric_weight', label: '体积重量(H*W*L/5000)' },
        { key: 'chargeable_weight', label: '用于计算的重量(KG)' },
        { key: 'operate', label: '操作' },
      ],
      adjust_fee: '0.00',
      userInfo: {},
      timer: null,
      reOverShow: false,
    }
  },
  computed: {
    detail() {
      return this.$store.state.shipment.detail
    },
    clientName({ detail, userInfo }) {
      return `${detail.sender_name}-${userInfo.id}`
    },
  },
  created() {
    this.freightItems.push({
      estimated_fee: this.detail.estimatedCost,
      insurance_fee: this.detail.insuranceCharge,
      tax_fee: this.detail.tax_fee,
      extra_fee: this.detail.extra_fee,
      delivery_fee: this.detail.endDeliveryCost,
      shipment_extraService: this.detail.extraServiceCharge,
      parcel_extraService: this.detail.packagesChargeableWeight,
      sub_total: this.detail.subTotalCost,
      total_cost: this.detail.totalEstimateCost,
    })
    try {
      const value = localStorage.getItem('userInfo')
      if (value) {
        this.userInfo = JSON.parse(value)
      }
    } catch (error) {
      console.log('error', error)
    }
  },
  destroyed() {
    this.timer = null
    clearTimeout(this.timer)
  },
  methods: {
    handleAdjustBlur() {
      this.adjust_fee = Number(this.adjust_fee).toFixed(2)
    },
    handleAddDiy() {
      this.$nextTick(() => {
        this.$refs.extraservice.handleAddDiy()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.adjust-input{
  min-width: 200px;
}
</style>
