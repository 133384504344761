<template>
  <div class="form-container mb-4">
    <div>
      <validation-observer
        ref="rulesForm"
        class="form"
      >
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >
          <b-row>
            <b-col
              class="mb-50 d-title"
              xl="3"
              lg="3"
              md="3"
              sm="6"
            >产品名称</b-col>
            <b-col
              class="mb-50 d-title"
              xl="3"
              lg="3"
              md="3"
              sm="6"
            >数量</b-col>
            <b-col
              class="mb-50 d-title"
              xl="3"
              lg="3"
              md="3"
              sm="6"
            >总价值</b-col>
          </b-row>
          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in items"
            :key="index"
            ref="row"
          >

            <!-- 产品名称 -->
            <b-col
              xl="3"
              lg="3"
              md="3"
              sm="6"
            >
              <b-form-group
                label-for="name"
              >
                <!-- rules="required" -->
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="name"
                >
                  <b-form-input
                    id="name"
                    v-model="item.name"
                    placeholder="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- 数量 -->
            <b-col
              xl="3"
              lg="3"
              md="3"
              sm="6"
            >
              <b-form-group
                label-for="quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="quantity"
                >
                  <b-form-input
                    id="quantity"
                    v-model="item.qty"
                    type="number"
                    placeholder="1"
                    @blur="handleNumberBlur(index,'qty')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- 总价值 -->
            <b-col
              xl="3"
              lg="3"
              md="3"
              sm="6"
            >
              <b-form-group
                label-for="price"
              >
                <validation-provider
                  #default="{ errors }"
                  name="price"
                  rules="required"
                >
                  <b-form-input
                    id="price"
                    v-model="item.value"
                    placeholder="10.00"
                    type="number"
                    @blur="handleNumberBlur(index,'value')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col
              xl="3"
              lg="3"
              md="3"
              sm="6"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>Delete</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="relief-primary"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Add New</span>
      </b-button>
      <div class="price">
        申报金额: AU${{ declareTotalPrice }}
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    declare: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      typeOptions: [],
      tagsOptions: [],
      items: [{
        name: undefined,
        qty: 1,
        value: 1,
      }],
      nextTodoId: 2,
      tags: [],
      selected: [''],
      required,
    }
  },
  computed: {
    declareTotalPrice({ items }) {
      const price = items.reduce((total, item) => total + Number(item.value), 0)
      return price.toFixed(2)
    },
  },
  watch: {
    declare: {
      handler(val) {
        this.items = val.map(item => ({
          id: item.id,
          name: item.name,
          qty: item.qty,
          value: item.value,
        }))
        this.initTrHeight()
      },
      immediate: true,
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    handleNumberBlur(index, type) {
      if (type === 'value') {
        this.items[index][type] = Number(this.items[index][type]).toFixed(2)
      } else {
        this.items[index][type] = Number(this.items[index][type])
      }
    },
    validationForm() {
      return new Promise(resolve => {
        this.$refs.rulesForm.validate().then(success => {
          resolve(success)
        })
      })
    },
    repeateAgain() {
      this.items.push({
        name: undefined,
        qty: 1,
        value: 0,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        this.$refs.rulesForm.validate()
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.price{
  font-size: 18px;
}
.ins{
  font-size:18px;
}
.check-wrap ::v-deep .custom-control-label::after,.check-wrap ::v-deep .custom-control-label::before{
  top:5px;
}
.form-group{
  margin-bottom: 0;
}
.d-title{
  font-size: 18px;
}
hr{
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
}
</style>
